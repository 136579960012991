// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
	REMOVE_CAMPSITE_IMAGE,
	SET_CAMPSITE_FILES_IMAGES,
	REMOVE_CAMPSITEMAP_IMAGE,
	SET_CAMPSITEMAP_FILES_IMAGES,
	SET_CAMPSITE_NAME,
	SET_CAMPSITE_NAME_ERROR,
	SET_CAMPSITE_REGISTER_KEY,
	ADD_CAMPSITE_CHANNEL,
	DELETE_CAMPSITE_CHANNEL,
	SET_CAMPSITE_CUSTOM_URI,
	SET_CAMPSITE_STARS
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchSearchCampsiteCityChannels } from "actions/channel";

const ReactTags = require("react-tag-autocomplete");

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	tags: {
		margin: "20px",
		width: "100%"
	},
	...customInputStyle,
	...customSelectStyle
};

class Step1 extends Component {
	sendState() {
		return this.state;
	}

	isValidated() {
		const { dispatch, name } = this.props;

		let success = true;
		if (!name || name.trim().length === 0) {
			dispatch({
				type: SET_CAMPSITE_NAME_ERROR
			});
			success = false;
		}
		return success;
	}

	onImageLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_CAMPSITE_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl, out: "logoUri" }]
		});
	};

	onImageRemoved = () => {
		this.props.dispatch({
			type: REMOVE_CAMPSITE_IMAGE
		});
	};

	onCampsiteMapLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_CAMPSITEMAP_FILES_IMAGES,
			payload: [
				{
					name,
					base64: imagePreviewUrl,
					ignoreQualityTransform: true,
					out: "mapUri"
				},
				{ name: name + "-small", base64: imagePreviewUrl, out: "mapSmallUri" }
			]
		});
	};

	onCampsiteMapRemoved = () => {
		this.props.dispatch({
			type: REMOVE_CAMPSITEMAP_IMAGE
		});
	};

	handleDelete = index => {
		const { dispatch } = this.props;

		if (index >= 0) {
			dispatch({
				type: DELETE_CAMPSITE_CHANNEL,
				index
			});
		}
	};

	handleAddition = channel => {
		this.props.dispatch({
			type: ADD_CAMPSITE_CHANNEL,
			value: channel
		});
	};

	onInput = query => {
		if (query?.length > 2) {
			this.props.fetchSearchCampsiteCityChannels(
				query,
				this.props.ignoreWeather
			);
		}
	};

	render() {
		const {
			dispatch,
			classes,
			name,
			nameErrorMsg,
			customUri,
			customUriErrorMsg,
			logoUri,
			mapSmallUri,
			registerkey,
			channels,
			channelsSuggestions,
			stars
		} = this.props;

		return (
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={4} md={4}>
					<legend>Logo</legend>
					<ImageUpload
						ref={this.child}
						onImageLoaded={this.onImageLoaded}
						onImageRemoved={this.onImageRemoved}
						photoUri={logoUri}
						addButtonProps={{
							color: "brown",
							round: true
						}}
						changeButtonProps={{
							color: "brown",
							round: true
						}}
						removeButtonProps={{
							color: "danger",
							round: true
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<legend>Plan intéractif</legend>
					<ImageUpload
						ref={this.child}
						onImageLoaded={this.onCampsiteMapLoaded}
						onImageRemoved={this.onCampsiteMapRemoved}
						photoUri={mapSmallUri}
						addButtonProps={{
							color: "brown",
							round: true
						}}
						changeButtonProps={{
							color: "brown",
							round: true
						}}
						removeButtonProps={{
							color: "danger",
							round: true
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Nom *"
						id="name"
						formControlProps={{
							fullWidth: true
						}}
						error={nameErrorMsg !== null}
						helperText={nameErrorMsg}
						inputProps={{
							value: name ? name : "",
							onChange: e =>
								dispatch({
									type: SET_CAMPSITE_NAME,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="URI"
						id="uri"
						formControlProps={{
							fullWidth: true
						}}
						error={customUriErrorMsg !== null}
						helperText={customUriErrorMsg}
						inputProps={{
							value: customUri ? customUri : "",
							onChange: e =>
								dispatch({
									type: SET_CAMPSITE_CUSTOM_URI,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel htmlFor="simple-select" className={classes.selectLabel}>
							Classification
						</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={stars ? stars : ""}
							onChange={(e, value) =>
								dispatch({
									type: SET_CAMPSITE_STARS,
									payload: value.props.children
								})
							}
							inputProps={{
								name: "simpleSelect",
								id: "simple-select"
							}}
						>
							{[0, 1, 2, 3, 4, 5].map(value => (
								<MenuItem
									classes={{
										root: classes.selectMenuItem
									}}
									value={value}
								>
									{value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Clé du camping pour rejoindre la communauté"
						id="registerkey"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: registerkey ? registerkey : "",
							onChange: e =>
								dispatch({
									type: SET_CAMPSITE_REGISTER_KEY,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<div className={classes.tags}>
					<FormHelperText>
						{
							"Canaux de diffusion depuis lesquelles recevoir publications, bons plans et météo"
						}
					</FormHelperText>
					<ReactTags
						placeholderText="Canaux de diffusion ..."
						tags={channels}
						suggestions={channelsSuggestions}
						minQueryLength={3}
						maxSuggestionsLength={10}
						onDelete={this.handleDelete}
						onAddition={this.handleAddition}
						suggestionsFilter={() => true}
						onInput={this.onInput}
						removeButtonText="Cliquez pour retirer"
						inputAttributes={{
							autocomplete: "whatever"
						}}
					/>
				</div>
			</GridContainer>
		);
	}
}

Step1.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		name,
		nameErrorMsg,
		logoUri,
		mapSmallUri,
		registerkey,
		channels,
		channelsSuggestions,
		customUri,
		customUriErrorMsg,
		stars,
		ignoreWeather
	} = state.campsiteReducer;
	return {
		name,
		nameErrorMsg,
		logoUri,
		mapSmallUri,
		registerkey,
		channels: Object.values(channels),
		channelsSuggestions: Object.values(channelsSuggestions),
		customUri,
		customUriErrorMsg,
		stars,
		ignoreWeather
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchSearchCampsiteCityChannels
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Step1));
