export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const SERVER_IN_MAINTENANCE = "SERVER_IN_MAINTENANCE";

export const FETCH_FAILURE = "FETCH_FAILURE";
export const CLEAR_SNACK_MESSAGE = "CLEAR_SNACK_MESSAGE";

export const SET_SID = "SET_SID";

export const WS_DISCONNECTED = "WS_DISCONNECTED";
export const WS_CONNECTED = "WS_CONNECTED";
export const WS_EVENT = "WS_EVENT";

export const CONNECTION = "CONNECTION";
export const CHAT = "CHAT";
export const LIVE = "LIVE";
export const LIVE_UPDATE = "LIVE_UPDATE";
export const LIVE_DELETE = "LIVE_DELETE";
export const OFFLINE = "OFFLINE";
export const ONLINE = "ONLINE";
export const UPDATE_GLOBAL_NOT_READ = "UPDATE_GLOBAL_NOT_READ";
export const NEW_CATEGORIES_TEMPLATES = "NEW_CATEGORIES_TEMPLATES";
export const RELOAD_CHAT_USERS = "RELOAD_CHAT_USERS";

export const TYPE_ACTIVITY = "Activity";
export const TYPE_TEMPLATE = "Template";

// TODO Move these constants
// States
export const EDITING = 1;
export const MODERATING = 2;
export const PUBLISHED = 3;
export const REFUSED = 40;

export const ROLE_CAMPER = "Camper";
export const ROLE_ADVERTISER = "Advertiser";
export const ROLE_EMPLOYEE = "Employee";
export const ROLE_ADMINISTRATOR = "Administrator";
export const ROLE_WEBMASTER = "Webmaster";
export const ROLE_NEIGHBOR = "Neighbor";

export const TYPE_CAMPSITE = "Campsite";
export const TYPE_CITY = "City";
export const TYPE_NEIGHBORHOOD = "Neighborhood";

// Channels
export const FETCH_GET_CHANNELS = "FETCH_GET_CHANNELS";
export const FETCH_GET_CITY_CHANNELS = "FETCH_GET_CITY_CHANNELS";
export const FETCH_GET_CAMPSITE_CHANNELS = "FETCH_GET_CAMPSITE_CHANNELS";
export const FETCH_GET_NEIGHBORHOOD_CHANNELS =
	"FETCH_GET_NEIGHBORHOOD_CHANNELS";
export const SET_CHANNELS = "SET_CHANNELS";
export const SET_CAMPSITE_CHANNELS = "SET_CAMPSITE_CHANNELS";
export const SET_NEIGHBORHOOD_CHANNELS = "SET_NEIGHBORHOOD_CHANNELS";
export const SET_CHANNELS_FAILURE = "SET_CHANNELS_FAILURE";
export const ADD_NEIGHBORHOOD_CHANNEL = "ADD_NEIGHBORHOOD_CHANNEL";
export const DELETE_NEIGHBORHOOD_CHANNEL = "DELETE_NEIGHBORHOOD_CHANNEL";
export const FETCH_SEARCH_GOODDEAL_CITY_CHANNELS =
	"FETCH_SEARCH_GOODDEAL_CITY_CHANNELS";
export const SET_GOODDEAL_CITY_CHANNELS = "SET_GOODDEAL_CITY_CHANNELS";
export const FETCH_SEARCH_GOODDEAL_CAMPSITE_CITY_CHANNELS =
	"FETCH_SEARCH_GOODDEAL_CAMPSITE_CITY_CHANNELS";
export const SET_GOODDEAL_CAMPSITE_CITY_CHANNELS =
	"SET_GOODDEAL_CAMPSITE_CITY_CHANNELS";
export const FETCH_SEARCH_GOODDEAL_CAMPSITE_CHANNELS =
	"FETCH_SEARCH_GOODDEAL_CAMPSITE_CHANNELS";
export const SET_GOODDEAL_CAMPSITE_CHANNELS = "SET_GOODDEAL_CAMPSITE_CHANNELS";
export const FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS =
	"FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS";
export const SET_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS =
	"SET_GOODDEAL_NEIGHBORHOOD_CITY_CHANNELS";
export const FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CHANNELS =
	"FETCH_SEARCH_GOODDEAL_NEIGHBORHOOD_CHANNELS";
export const SET_GOODDEAL_NEIGHBORHOOD_CHANNELS =
	"SET_GOODDEAL_NEIGHBORHOOD_CHANNELS";
export const FETCH_SEARCH_EVENT_CITY_CHANNELS =
	"FETCH_SEARCH_EVENT_CITY_CHANNELS";
export const SET_EVENT_CITY_CHANNELS = "SET_EVENT_CITY_CHANNELS";
export const FETCH_SEARCH_EVENT_CAMPSITE_CITY_CHANNELS =
	"FETCH_SEARCH_EVENT_CAMPSITE_CITY_CHANNELS";
export const SET_EVENT_CAMPSITE_CITY_CHANNELS =
	"SET_EVENT_CAMPSITE_CITY_CHANNELS";
export const FETCH_SEARCH_EVENT_CAMPSITE_CHANNELS =
	"FETCH_SEARCH_EVENT_CAMPSITE_CHANNELS";
export const SET_EVENT_CAMPSITE_CHANNELS = "SET_EVENT_CAMPSITE_CHANNELS";
export const FETCH_SEARCH_EVENT_NEIGHBORHOOD_CITY_CHANNELS =
	"FETCH_SEARCH_EVENT_NEIGHBORHOOD_CITY_CHANNELS";
export const SET_EVENT_NEIGHBORHOOD_CITY_CHANNELS =
	"SET_EVENT_NEIGHBORHOOD_CITY_CHANNELS";
export const FETCH_SEARCH_EVENT_NEIGHBORHOOD_CHANNELS =
	"FETCH_SEARCH_EVENT_NEIGHBORHOOD_CHANNELS";
export const SET_EVENT_NEIGHBORHOOD_CHANNELS =
	"SET_EVENT_NEIGHBORHOOD_CHANNELS";
export const FETCH_SEARCH_CITY_CHANNELS = "FETCH_SEARCH_CITY_CHANNELS";
export const SET_CITY_CHANNELS = "SET_CITY_CHANNELS";
export const FETCH_SEARCH_CAMPSITE_CITY_CHANNELS =
	"FETCH_SEARCH_CAMPSITE_CITY_CHANNELS";
export const SET_CAMPSITE_CITY_CHANNELS = "SET_CAMPSITE_CITY_CHANNELS";
export const FETCH_SEARCH_NEIGHBORHOOD_CITY_CHANNELS =
	"FETCH_SEARCH_NEIGHBORHOOD_CITY_CHANNELS";
export const SET_NEIGHBORHOOD_CITY_CHANNELS = "SET_NEIGHBORHOOD_CITY_CHANNELS";

// COMMUNITIES
export const FETCH_GET_CURRENT_COMMUNITY_DETAILS =
	"FETCH_GET_CURRENT_COMMUNITY_DETAILS";
export const SET_CURRENT_COMMUNITY_DETAILS_RESULT =
	"SET_CURRENT_COMMUNITY_DETAILS_RESULT";
export const SET_CURRENT_COMMUNITY_DETAILS_FAILURE =
	"SET_CURRENT_COMMUNITY_DETAILS_FAILURE";
export const FETCH_GET_COMMUNITY_DETAILS = "FETCH_GET_COMMUNITY_DETAILS";
export const SET_COMMUNITY_DETAILS_RESULT = "SET_COMMUNITY_DETAILS_RESULT";
export const SET_COMMUNITY_DETAILS_FAILURE = "SET_COMMUNITY_DETAILS_FAILURE";
export const FETCH_REORDER_COMMUNITY_EQUIPMENTS =
	"FETCH_REORDER_COMMUNITY_EQUIPMENTS";
export const SET_REORDER_COMMUNITY_EQUIPMENTS_RESULT =
	"SET_REORDER_COMMUNITY_EQUIPMENTS_RESULT";
export const SET_REORDER_COMMUNITY_EQUIPMENTS_FAILURE =
	"SET_REORDER_COMMUNITY_EQUIPMENTS_FAILURE";
export const COMMUNITY_EQUIPMENTS_ON_DRAG_END =
	"COMMUNITY_EQUIPMENTS_ON_DRAG_END";

export const FETCH_GET_HOME_EVENTS = "FETCH_GET_HOME_EVENTS";
export const SET_HOME_EVENTS_RESULT = "SET_HOME_EVENTS_RESULT";
export const SET_HOME_EVENTS_FAILURE = "SET_HOME_EVENTS_FAILURE";
export const FETCH_GET_OWN_EVENTS = "FETCH_GET_OWN_EVENTS";
export const SET_OWN_EVENTS_RESULT = "SET_OWN_EVENTS_RESULT";
export const SET_OWN_EVENTS_FAILURE = "SET_OWN_EVENTS_FAILURE";
export const FETCH_GET_EVENT = "FETCH_GET_EVENT";
export const SET_GET_EVENT_RESULT = "SET_GET_EVENT_RESULT";
export const SET_GET_EVENT_FAILURE = "SET_GET_EVENT_FAILURE";
export const FETCH_DELETE_EVENT = "FETCH_DELETE_EVENT";
export const SET_DELETE_EVENT_RESULT = "SET_DELETE_EVENT_RESULT";
export const SET_DELETE_EVENT_FAILURE = "SET_DELETE_EVENT_FAILURE";
export const RESET_EVENT = "RESET_EVENT";
export const ADD_POST_CAMPSITE = "ADD_POST_CAMPSITE";
export const DELETE_POST_CAMPSITE = "DELETE_POST_CAMPSITE";
export const MISSING_POST_CAMPSITE = "MISSING_POST_CAMPSITE";
export const FETCH_PUBLISH_EVENT = "FETCH_PUBLISH_EVENT";
export const SET_PUBLISH_EVENT_RESULT = "SET_PUBLISH_EVENT_RESULT";
export const SET_PUBLISH_EVENT_FAILURE = "SET_PUBLISH_EVENT_FAILURE";
export const FETCH_ADD_EVENT = "FETCH_ADD_EVENT";
export const SET_ADD_EVENT_RESULT = "SET_ADD_EVENT_RESULT";
export const SET_ADD_EVENT_FAILURE = "SET_ADD_EVENT_FAILURE";
export const FETCH_UPDATE_EVENT = "FETCH_UPDATE_EVENT";
export const SET_UPDATE_EVENT_RESULT = "SET_UPDATE_EVENT_RESULT";
export const SET_UPDATE_EVENT_FAILURE = "SET_UPDATE_EVENT_FAILURE";
export const FETCH_MODERATING_EVENT_ACCEPT = "FETCH_MODERATING_EVENT_ACCEPT";
export const FETCH_MODERATING_EVENT_REFUSE = "FETCH_MODERATING_EVENT_REFUSE";
export const SET_MODERATING_EVENT_ACCEPT_RESULT =
	"SET_MODERATING_EVENT_ACCEPT_RESULT";
export const SET_MODERATING_EVENT_REFUSE_RESULT =
	"SET_MODERATING_EVENT_REFUSE_RESULT";
export const SET_MODERATING_EVENT_ACCEPT_FAILURE =
	"SET_MODERATING_EVENT_ACCEPT_FAILURE";
export const SET_MODERATING_EVENT_REFUSE_FAILURE =
	"SET_MODERATING_EVENT_REFUSE_FAILURE";
export const FETCH_GET_EVENT_TYPES = "FETCH_GET_EVENT_TYPES";
export const SET_EVENT_TYPES = "SET_EVENT_TYPES";
export const SET_EVENT_TYPES_FAILURE = "SET_EVENT_TYPES_FAILURE";
export const FETCH_GET_EVENT_CHANNELS = "FETCH_GET_EVENT_CHANNELS";
export const SET_GET_EVENT_CHANNELS_RESULT = "SET_GET_EVENT_CHANNELS_RESULT";
export const SET_GET_EVENT_CHANNELS_FAILURE = "SET_GET_EVENT_CHANNELS_FAILURE";

export const SET_POST_FILES_IMAGES = "SET_POST_FILES_IMAGES";
export const REMOVE_POST_IMAGE = "REMOVE_POST_IMAGE";
export const SET_POST_DESCRIPTION_FR = "SET_POST_DESCRIPTION_FR";
export const SET_POST_DESCRIPTION_EN = "SET_POST_DESCRIPTION_EN";
export const SET_POST_TITLE_FR = "SET_POST_TITLE_FR";
export const SET_POST_TITLE_EN = "SET_POST_TITLE_EN";
export const SET_POST_DESCRIPTION_ERROR = "SET_POST_DESCRIPTION_ERROR";
export const SET_POST_TITLE_ERROR = "SET_POST_TITLE_ERROR";
export const SET_POST_URL = "SET_POST_URL";
export const SET_POST_GOODDEAL = "SET_POST_GOODDEAL";
export const CLEAR_POST_URL = "CLEAR_POST_URL";
export const SET_POST_DATE = "SET_POST_DATE";
export const SET_POST_URL_ERROR = "SET_POST_URL_ERROR";
export const SET_POST_SORTED = "SET_POST_SORTED";
export const SET_POST_TYPE = "SET_POST_TYPE";
export const SET_POST_TYPE_ERROR = "SET_POST_TYPE_ERROR";
export const SET_POST_GOODDEAL_ERROR = "SET_POST_GOODDEAL_ERROR";
export const SET_POST_PROMOTION_FR = "SET_POST_PROMOTION_FR";
export const SET_POST_START_DATE_ERROR = "SET_POST_START_DATE_ERROR";
export const SET_POST_PROMOTION_ERROR = "SET_POST_PROMOTION_ERROR";
export const SET_POST_PROMOTION_EN = "SET_POST_PROMOTION_EN";
export const ADD_WIZARD = "ADD_WIZARD";
export const CLEAR_SNACK_WARN_MSG = "CLEAR_SNACK_WARN_MSG";
export const ADD_POST_CITY = "ADD_POST_CITY";
export const DELETE_POST_CITY = "DELETE_POST_CITY";
export const MISSING_POST_CITY = "MISSING_POST_CITY";
export const MISSING_POST_CHANNEL = "MISSING_POST_CHANNEL";
export const ADD_POST_CHANNEL = "ADD_POST_CHANNEL";
export const DELETE_POST_CHANNEL = "DELETE_POST_CHANNEL";
export const ADD_POST_CITY_CHANNEL = "ADD_POST_CITY_CHANNEL";
export const DELETE_POST_CITY_CHANNEL = "DELETE_POST_CITY_CHANNEL";
export const ADD_POST_CAMPSITE_CHANNEL = "ADD_POST_CAMPSITE_CHANNEL";
export const ADD_POST_CAMPSITE_CITY_CHANNEL = "ADD_POST_CAMPSITE_CITY_CHANNEL";
export const DELETE_POST_CAMPSITE_CHANNEL = "DELETE_POST_CAMPSITE_CHANNEL";
export const DELETE_POST_CAMPSITE_CITY_CHANNEL =
	"DELETE_POST_CAMPSITE_CITY_CHANNEL";
export const ADD_POST_NEIGHBORHOOD_CHANNEL = "ADD_POST_NEIGHBORHOOD_CHANNEL";
export const ADD_POST_NEIGHBORHOOD_CITY_CHANNEL =
	"ADD_POST_NEIGHBORHOOD_CITY_CHANNEL";
export const DELETE_POST_NEIGHBORHOOD_CHANNEL =
	"DELETE_POST_NEIGHBORHOOD_CHANNEL";
export const DELETE_POST_NEIGHBORHOOD_CITY_CHANNEL =
	"DELETE_POST_NEIGHBORHOOD_CITY_CHANNEL";

export const FETCH_GET_CAMPSITES = "FETCH_GET_CAMPSITES";
export const SET_CAMPSITES = "SET_CAMPSITES";
export const TOGGLE_SHOW_INFO = "TOGGLE_SHOW_INFO";
export const SET_CAMPSITES_FAILURE = "SET_CAMPSITES_FAILURE";
export const REMOVE_CAMPSITE_IMAGE = "REMOVE_CAMPSITE_IMAGE";
export const REMOVE_CAMPSITEMAP_IMAGE = "REMOVE_CAMPSITEMAP_IMAGE";
export const SET_CAMPSITE_FILES_IMAGES = "SET_CAMPSITE_FILES_IMAGES";
export const SET_CAMPSITEMAP_FILES_IMAGES = "SET_CAMPSITEMAP_FILES_IMAGES";
export const SET_CAMPSITE_NAME = "SET_CAMPSITE_NAME";
export const SET_CAMPSITE_CUSTOM_URI = "SET_CAMPSITE_CUSTOM_URI";
export const SET_CAMPSITE_STARS = "SET_CAMPSITE_STARS";
export const SET_CAMPSITE_REGISTER_KEY = "SET_CAMPSITE_REGISTER_KEY";
export const SET_CAMPSITE_NAME_ERROR = "SET_CAMPSITE_NAME_ERROR";
export const SET_CAMPSITE_CUSTOM_URI_ERROR = "SET_CAMPSITE_CUSTOM_URI_ERROR";
export const SET_CAMPSITE_LOCALIZATION = "SET_CAMPSITE_LOCALIZATION";
export const FETCH_ADD_CAMPSITE = "FETCH_ADD_CAMPSITE";
export const SET_FETCH_ADD_CAMPSITE_RESULT = "SET_FETCH_ADD_CAMPSITE_RESULT";
export const SET_FETCH_ADD_CAMPSITE_FAILURE = "SET_FETCH_ADD_CAMPSITE_FAILURE";
export const FETCH_UPDATE_CAMPSITE = "FETCH_UPDATE_CAMPSITE";
export const SET_FETCH_UPDATE_CAMPSITE_RESULT =
	"SET_FETCH_UPDATE_CAMPSITE_RESULT";
export const SET_FETCH_UPDATE_CAMPSITE_FAILURE =
	"SET_FETCH_UPDATE_CAMPSITE_FAILURE";
export const RESET_CAMPSITE = "RESET_CAMPSITE";
export const FETCH_GET_CAMPSITE_DETAILS = "FETCH_GET_CAMPSITE_DETAIL";
export const SET_CAMPSITE_DETAILS_RESULT = "SET_CAMPSITE_DETAILS_RESULT";
export const SET_CAMPSITE_DETAILS_FAILURE = "SET_CAMPSITE_DETAILS_FAILURE";
export const SET_CAMPSITE_PHONE = "SET_CAMPSITE_PHONE";
export const CLEAR_CAMPSITE_PHONE = "CLEAR_CAMPSITE_PHONE";
export const SET_CAMPSITE_PHONE_ERROR = "SET_CAMPSITE_PHONE_ERROR";
export const SET_CAMPSITE_FAX = "SET_CAMPSITE_FAX";
export const CLEAR_CAMPSITE_FAX = "CLEAR_CAMPSITE_FAX";
export const SET_CAMPSITE_FAX_ERROR = "SET_CAMPSITE_FAX_ERROR";
export const SET_CAMPSITE_ADDRESS = "SET_CAMPSITE_ADDRESS";
export const SET_CAMPSITE_ZIP_CODE = "SET_CAMPSITE_ZIP_CODE";
export const SET_CAMPSITE_CITY = "SET_CAMPSITE_CITY";
export const SET_CAMPSITE_WEB_SITE_URL = "SET_CAMPSITE_WEB_SITE_URL";
export const CLEAR_CAMPSITE_WEB_SITE_URL = "CLEAR_CAMPSITE_WEB_SITE_URL";
export const SET_CAMPSITE_WEB_SITE_URL_ERROR =
	"SET_CAMPSITE_WEB_SITE_URL_ERROR";
export const SET_CAMPSITE_MAIL = "SET_CAMPSITE_MAIL";
export const CLEAR_CAMPSITE_MAIL = "CLEAR_CAMPSITE_MAIL";
export const SET_CAMPSITE_MAIL_ERROR = "SET_CAMPSITE_MAIL_ERROR";
export const FETCH_GET_CURRENT_CAMPSITE_DETAILS =
	"FETCH_GET_CURRENT_CAMPSITE_DETAILS";
export const SET_CURRENT_CAMPSITE_DETAILS_RESULT =
	"SET_CURRENT_CAMPSITE_DETAILS_RESULT";
export const SET_CURRENT_CAMPSITE_DETAILS_FAILURE =
	"SET_CURRENT_CAMPSITE_DETAILS_FAILURE";
export const SET_CAMPSITE_SELECTED_CITY = "SET_CAMPSITE_SELECTED_CITY";
export const SET_CAMPSITE_SELECTED_ADDRESS = "SET_CAMPSITE_SELECTED_ADDRESS";
export const SET_CAMPSITE_SELECTED_ZIPCODE = "SET_CAMPSITE_SELECTED_ZIPCODE";
export const FETCH_DELETE_CAMPSITE = "FETCH_DELETE_CAMPSITE";
export const SET_FETCH_DELETE_CAMPSITE_RESULT =
	"SET_FETCH_DELETE_CAMPSITE_RESULT";
export const SET_FETCH_DELETE_CAMPSITE_FAILURE =
	"SET_FETCH_DELETE_CAMPSITE_FAILURE";
export const CLEAR_CAMPSITE_VALIDATE = "CLEAR_CAMPSITE_VALIDATE";
export const VALIDATE_CAMPSITE_INPUTS = "VALIDATE_CAMPSITE_INPUTS";
export const FETCH_REGISTER_CAMPSITE = "FETCH_REGISTER_CAMPSITE";
export const SET_REGISTER_CAMPSITE_RESULT = "SET_REGISTER_CAMPSITE_RESULT";
export const SET_REGISTER_CAMPSITE_FAILURE = "SET_REGISTER_CAMPSITE_FAILURE";
export const SET_DELETE_CAMPSITE_CONFIRMATION_NAME =
	"SET_DELETE_CAMPSITE_CONFIRMATION_NAME";
export const ADD_CAMPSITE_CHANNEL = "ADD_CAMPSITE_CHANNEL";
export const DELETE_CAMPSITE_CHANNEL = "DELETE_CAMPSITE_CHANNEL";
export const ADD_CAMPSITE_CITY = "ADD_CAMPSITE_CITY";
export const DELETE_CAMPSITE_CITY = "DELETE_CAMPSITE_CITY";
export const ADD_NEIGHBORHOOD_CITY = "ADD_NEIGHBORHOOD_CITY";
export const DELETE_NEIGHBORHOOD_CITY = "DELETE_NEIGHBORHOOD_CITY";

// NEIGHBORHOOD
export const FETCH_GET_NEIGHBORHOODS = "FETCH_GET_NEIGHBORHOODS";
export const SET_NEIGHBORHOODS = "SET_NEIGHBORHOODS";
export const SET_NEIGHBORHOODS_NAMES = "SET_NEIGHBORHOODS_NAMES";
export const SET_NEIGHBORHOODS_SORTED = "SET_NEIGHBORHOODS_SORTED";
export const SET_NEIGHBORHOODS_FAILURE = "SET_NEIGHBORHOODS_FAILURE";
export const REMOVE_NEIGHBORHOOD_IMAGE = "REMOVE_NEIGHBORHOOD_IMAGE";
export const REMOVE_NEIGHBORHOODMAP_IMAGE = "REMOVE_NEIGHBORHOODMAP_IMAGE";
export const SET_NEIGHBORHOOD_FILES_IMAGES = "SET_NEIGHBORHOOD_FILES_IMAGES";
export const SET_NEIGHBORHOODMAP_FILES_IMAGES =
	"SET_NEIGHBORHOODMAP_FILES_IMAGES";
export const SET_NEIGHBORHOOD_NAME = "SET_NEIGHBORHOOD_NAME";
export const SET_NEIGHBORHOOD_REGISTER_KEY = "SET_NEIGHBORHOOD_REGISTER_KEY";
export const SET_NEIGHBORHOOD_NAME_ERROR = "SET_NEIGHBORHOOD_NAME_ERROR";
export const SET_NEIGHBORHOOD_LOCALIZATION = "SET_NEIGHBORHOOD_LOCALIZATION";
export const FETCH_ADD_NEIGHBORHOOD = "FETCH_ADD_NEIGHBORHOOD";
export const SET_FETCH_ADD_NEIGHBORHOOD_RESULT =
	"SET_FETCH_ADD_NEIGHBORHOOD_RESULT";
export const SET_FETCH_ADD_NEIGHBORHOOD_FAILURE =
	"SET_FETCH_ADD_NEIGHBORHOOD_FAILURE";
export const FETCH_UPDATE_NEIGHBORHOOD = "FETCH_UPDATE_NEIGHBORHOOD";
export const SET_FETCH_UPDATE_NEIGHBORHOOD_RESULT =
	"SET_FETCH_UPDATE_NEIGHBORHOOD_RESULT";
export const SET_FETCH_UPDATE_NEIGHBORHOOD_FAILURE =
	"SET_FETCH_UPDATE_NEIGHBORHOOD_FAILURE";
export const RESET_NEIGHBORHOOD = "RESET_NEIGHBORHOOD";
export const FETCH_GET_NEIGHBORHOOD_DETAILS = "FETCH_GET_NEIGHBORHOOD_DETAIL";
export const SET_NEIGHBORHOOD_DETAILS_RESULT =
	"SET_NEIGHBORHOOD_DETAILS_RESULT";
export const SET_NEIGHBORHOOD_DETAILS_FAILURE =
	"SET_NEIGHBORHOOD_DETAILS_FAILURE";
export const SET_NEIGHBORHOOD_PHONE = "SET_NEIGHBORHOOD_PHONE";
export const CLEAR_NEIGHBORHOOD_PHONE = "CLEAR_NEIGHBORHOOD_PHONE";
export const SET_NEIGHBORHOOD_PHONE_ERROR = "SET_NEIGHBORHOOD_PHONE_ERROR";
export const SET_NEIGHBORHOOD_FAX = "SET_NEIGHBORHOOD_FAX";
export const CLEAR_NEIGHBORHOOD_FAX = "CLEAR_NEIGHBORHOOD_FAX";
export const SET_NEIGHBORHOOD_FAX_ERROR = "SET_NEIGHBORHOOD_FAX_ERROR";
export const SET_NEIGHBORHOOD_ADDRESS = "SET_NEIGHBORHOOD_ADDRESS";
export const SET_NEIGHBORHOOD_ZIP_CODE = "SET_NEIGHBORHOOD_ZIP_CODE";
export const SET_NEIGHBORHOOD_CITY = "SET_NEIGHBORHOOD_CITY";
export const SET_NEIGHBORHOOD_WEB_SITE_URL = "SET_NEIGHBORHOOD_WEB_SITE_URL";
export const CLEAR_NEIGHBORHOOD_WEB_SITE_URL =
	"CLEAR_NEIGHBORHOOD_WEB_SITE_URL";
export const SET_NEIGHBORHOOD_WEB_SITE_URL_ERROR =
	"SET_NEIGHBORHOOD_WEB_SITE_URL_ERROR";
export const SET_NEIGHBORHOOD_MAIL = "SET_NEIGHBORHOOD_MAIL";
export const CLEAR_NEIGHBORHOOD_MAIL = "CLEAR_NEIGHBORHOOD_MAIL";
export const SET_NEIGHBORHOOD_MAIL_ERROR = "SET_NEIGHBORHOOD_MAIL_ERROR";
export const FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS =
	"FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS";
export const SET_CURRENT_NEIGHBORHOOD_DETAILS_RESULT =
	"SET_CURRENT_NEIGHBORHOOD_DETAILS_RESULT";
export const SET_CURRENT_NEIGHBORHOOD_DETAILS_FAILURE =
	"SET_CURRENT_NEIGHBORHOOD_DETAILS_FAILURE";
export const SET_NEIGHBORHOOD_SELECTED_CITY = "SET_NEIGHBORHOOD_SELECTED_CITY";
export const SET_NEIGHBORHOOD_SELECTED_ADDRESS =
	"SET_NEIGHBORHOOD_SELECTED_ADDRESS";
export const SET_NEIGHBORHOOD_SELECTED_ZIPCODE =
	"SET_NEIGHBORHOOD_SELECTED_ZIPCODE";
export const FETCH_DELETE_NEIGHBORHOOD = "FETCH_DELETE_NEIGHBORHOOD";
export const SET_FETCH_DELETE_NEIGHBORHOOD_RESULT =
	"SET_FETCH_DELETE_NEIGHBORHOOD_RESULT";
export const SET_FETCH_DELETE_NEIGHBORHOOD_FAILURE =
	"SET_FETCH_DELETE_NEIGHBORHOOD_FAILURE";
export const NEIGHBORHOOD_EQUIPMENTS_ON_DRAG_END =
	"NEIGHBORHOOD_EQUIPMENTS_ON_DRAG_END";
export const FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS =
	"FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS";
export const SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_RESULT =
	"SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_RESULT";
export const SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_FAILURE =
	"SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_FAILURE";
export const CLEAR_NEIGHBORHOOD_VALIDATE = "CLEAR_NEIGHBORHOOD_VALIDATE";
export const VALIDATE_NEIGHBORHOOD_INPUTS = "VALIDATE_NEIGHBORHOOD_INPUTS";
export const FETCH_REGISTER_NEIGHBORHOOD = "FETCH_REGISTER_NEIGHBORHOOD";
export const SET_REGISTER_NEIGHBORHOOD_RESULT =
	"SET_REGISTER_NEIGHBORHOOD_RESULT";
export const SET_REGISTER_NEIGHBORHOOD_FAILURE =
	"SET_REGISTER_NEIGHBORHOOD_FAILURE";
export const SET_DELETE_NEIGHBORHOOD_CONFIRMATION_NAME =
	"SET_DELETE_NEIGHBORHOOD_CONFIRMATION_NAME";
export const SET_NEIGHBORHOOD_BUILDING = "SET_NEIGHBORHOOD_BUILDING";
export const SET_NEIGHBORHOOD_RESIDENCE = "SET_NEIGHBORHOOD_RESIDENCE";
export const SET_NEIGHBORHOOD_DISTRICT = "SET_NEIGHBORHOOD_DISTRICT";
export const SET_NEIGHBORHOODS_NAME_FILTER = "SET_NEIGHBORHOODS_NAME_FILTER";
// CITY
export const FETCH_GET_CITIES_NAMES = "FETCH_GET_CITIES_NAMES";
export const FETCH_GET_CITIES = "FETCH_GET_CITIES";
export const FETCH_GET_NEAR_CITIES = "FETCH_GET_NEAR_CITIES";
export const SET_CITIES = "SET_CITIES";
export const SET_NEAR_CITIES = "SET_NEAR_CITIES";
export const SET_CITIES_FAILURE = "SET_CITIES_FAILURE";
export const SET_NEAR_CITIES_FAILURE = "SET_NEAR_CITIES_FAILURE";
export const SET_CITIES_NAMES = "SET_CITIES_NAMES";
export const SET_CAMPSITES_SORTED = "SET_CAMPSITES_SORTED";
export const SET_CAMPSITES_NAME_FILTER = "SET_CAMPSITES_NAME_FILTER";
export const FETCH_GET_CAMPSITES_NAMES = "FETCH_GET_CAMPSITES_NAMES";
export const SET_CAMPSITES_NAMES = "SET_CAMPSITES_NAMES";
export const SET_CAMPSITES_NAMES_FAILURE = "SET_CAMPSITES_NAMES_FAILURE";
export const SET_CITIES_SORTED = "SET_CITIES_SORTED";
export const SET_CITIES_NAME_FILTER = "SET_CITIES_NAME_FILTER";
export const SET_CITIES_NAMES_FAILURE = "SET_CITIES_NAMES_FAILURE";
export const REMOVE_CITY_IMAGE = "REMOVE_CITY_IMAGE";
export const REMOVE_CITY_LOGO_IMAGE = "REMOVE_CITY_LOGO_IMAGE";
export const REMOVE_CITYMAP_IMAGE = "REMOVE_CITYMAP_IMAGE";
export const SET_CITY_FILES_IMAGES = "SET_CITY_FILES_IMAGES";
export const SET_CITY_LOGO_FILES_IMAGES = "SET_CITY_LOGO_FILES_IMAGES";
export const SET_CITYMAP_FILES_IMAGES = "SET_CITYMAP_FILES_IMAGES";
export const SET_CITY_NAME = "SET_CITY_NAME";
export const SET_CITY_CUSTOM_URI = "SET_CITY_CUSTOM_URI";
export const SET_CITY_REGISTER_KEY = "SET_CITY_REGISTER_KEY";
export const SET_CITY_NAME_ERROR = "SET_CITY_NAME_ERROR";
export const SET_CITY_CUSTOM_URI_ERROR = "SET_CITY_CUSTOM_URI_ERROR";
export const SET_CITY_LOCALIZATION = "SET_CITY_LOCALIZATION";
export const FETCH_ADD_CITY = "FETCH_ADD_CITY";
export const SET_FETCH_ADD_CITY_RESULT = "SET_FETCH_ADD_CITY_RESULT";
export const SET_FETCH_ADD_CITY_FAILURE = "SET_FETCH_ADD_CITY_FAILURE";
export const FETCH_UPDATE_CITY = "FETCH_UPDATE_CITY";
export const SET_FETCH_UPDATE_CITY_RESULT = "SET_FETCH_UPDATE_CITY_RESULT";
export const SET_FETCH_UPDATE_CITY_FAILURE = "SET_FETCH_UPDATE_CITY_FAILURE";
export const RESET_CITY = "RESET_CITY";
export const FETCH_GET_CITY_DETAILS = "FETCH_GET_CITY_DETAIL";
export const SET_CITY_DETAILS_RESULT = "SET_CITY_DETAILS_RESULT";
export const SET_CITY_DETAILS_FAILURE = "SET_CITY_DETAILS_FAILURE";
export const SET_CITY_PHONE = "SET_CITY_PHONE";
export const CLEAR_CITY_PHONE = "CLEAR_CITY_PHONE";
export const SET_CITY_PHONE_ERROR = "SET_CITY_PHONE_ERROR";
export const SET_CITY_FAX = "SET_CITY_FAX";
export const CLEAR_CITY_FAX = "CLEAR_CITY_FAX";
export const SET_CITY_FAX_ERROR = "SET_CITY_FAX_ERROR";
export const SET_CITY_ADDRESS = "SET_CITY_ADDRESS";
export const SET_CITY_ZIP_CODE = "SET_CITY_ZIP_CODE";
export const SET_CITY_CITY = "SET_CITY_CITY";
export const SET_CITY_WEB_SITE_URL = "SET_CITY_WEB_SITE_URL";
export const CLEAR_CITY_WEB_SITE_URL = "CLEAR_CITY_WEB_SITE_URL";
export const SET_CITY_WEB_SITE_URL_ERROR = "SET_CITY_WEB_SITE_URL_ERROR";
export const SET_CITY_MAIL = "SET_CITY_MAIL";
export const CLEAR_CITY_MAIL = "CLEAR_CITY_MAIL";
export const SET_CITY_MAIL_ERROR = "SET_CITY_MAIL_ERROR";
export const FETCH_GET_CURRENT_CITY_DETAILS = "FETCH_GET_CURRENT_CITY_DETAILS";
export const SET_CURRENT_CITY_DETAILS_RESULT =
	"SET_CURRENT_CITY_DETAILS_RESULT";
export const SET_CURRENT_CITY_DETAILS_FAILURE =
	"SET_CURRENT_CITY_DETAILS_FAILURE";
export const SET_CITY_SELECTED_CITY = "SET_CITY_SELECTED_CITY";
export const SET_CITY_SELECTED_ADDRESS = "SET_CITY_SELECTED_ADDRESS";
export const SET_CITY_SELECTED_ZIPCODE = "SET_CITY_SELECTED_ZIPCODE";
export const FETCH_DELETE_CITY = "FETCH_DELETE_CITY";
export const SET_FETCH_DELETE_CITY_RESULT = "SET_FETCH_DELETE_CITY_RESULT";
export const SET_FETCH_DELETE_CITY_FAILURE = "SET_FETCH_DELETE_CITY_FAILURE";
export const CITY_EQUIPMENTS_ON_DRAG_END = "CITY_EQUIPMENTS_ON_DRAG_END";
export const FETCH_REORDER_CITY_EQUIPMENTS = "FETCH_REORDER_CITY_EQUIPMENTS";
export const SET_REORDER_CITY_EQUIPMENTS_RESULT =
	"SET_REORDER_CITY_EQUIPMENTS_RESULT";
export const SET_REORDER_CITY_EQUIPMENTS_FAILURE =
	"SET_REORDER_CITY_EQUIPMENTS_FAILURE";
export const CLEAR_CITY_VALIDATE = "CLEAR_CITY_VALIDATE";
export const VALIDATE_CITY_INPUTS = "VALIDATE_CITY_INPUTS";
export const FETCH_REGISTER_CITY = "FETCH_REGISTER_CITY";
export const SET_REGISTER_CITY_RESULT = "SET_REGISTER_CITY_RESULT";
export const SET_REGISTER_CITY_FAILURE = "SET_REGISTER_CITY_FAILURE";
export const SET_DELETE_CITY_CONFIRMATION_NAME =
	"SET_DELETE_CITY_CONFIRMATION_NAME";
export const ADD_CITY_CHANNEL = "ADD_CITY_CHANNEL";
export const DELETE_CITY_CHANNEL = "DELETE_CITY_CHANNEL";
export const FETCH_GET_CITIES_NAMES_AND_CHANNELS =
	"FETCH_GET_CITIES_NAMES_AND_CHANNELS";
export const SET_CITIES_TAGS = "SET_CITIES_TAGS";
export const SET_CITIES_TAGS_FAILURE = "SET_CITIES_TAGS_FAILURE";

// LOGIN
export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_LOGIN_WITH_FB = "FETCH_LOGIN_WITH_FB";
export const FETCH_SIGNUP = "FETCH_SIGNUP";
export const FETCH_SIGNUP_WITH_FB = "FETCH_SIGNUP_WITH_FB";
export const SIGNUP_RESULT = "SIGNUP_RESULT";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_EMAIL_OR_PSEUDO = "SET_EMAIL_OR_PSEUDO";
export const SET_PASSWORD = "SET_PASSWORD";
export const VALIDATE_INPUTS = "VALIDATE_INPUTS";
export const VALIDATE_FB_INPUTS = "VALIDATE_FB_INPUTS";
export const CLEAR_VALIDATE = "CLEAR_VALIDATE";
export const CLEAR_VALIDATE_FB = "CLEAR_VALIDATE_FB";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_PASSWORD_CAMP = "SET_PASSWORD_CAMP";
export const FETCH_FORGET_PASSWORD = "FETCH_FORGET_PASSWORD";
export const FORGET_PASSWORD_RESULT = "FORGET_PASSWORD_RESULT";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const SET_CAMPSITE_ID = "SET_CAMPSITE_ID";
export const SET_CAMPSITE = "SET_CAMPSITE";
export const SET_CGU_CHECKED = "SET_CGU_CHECKED";
export const SET_LOGIN_WITH_FB_RESULT = "SET_LOGIN_WITH_FB_RESULT";
export const SET_LOGIN_WITH_FB_ERROR = "SET_LOGIN_WITH_FB_ERROR";
export const FETCH_LOGIN_USING_TOKEN = "FETCH_LOGIN_USING_TOKEN";
export const SET_STRATEGY = "SET_STRATEGY";

// PROFILE
export const FETCH_GET_USER = "FETCH_GET_USER";
export const SET_USER_FAILURE = "SET_USER_FAILURE";
export const FETCH_GET_HOBBIES = "FETCH_GET_HOBBIES";
export const SET_HOBBIES = "SET_HOBBIES";
export const SET_HOBBIES_FAILURE = "SET_HOBBIES_FAILURE";
export const SET_PROFILE_DETAILS = "SET_PROFILE_DETAILS";
export const FETCH_UPDATE_PROFILE = "FETCH_UPDATE_PROFILE";
export const FETCH_UPDATE_BOOKING = "FETCH_UPDATE_BOOKING";
export const FETCH_GET_PROFILE = "FETCH_GET_PROFILE";
export const CLEAR_PHOTO = "CLEAR_PHOTO";
export const CLEAR_PROFILE_VALIDATE = "CLEAR_PROFILE_VALIDATE";
export const VALIDATE_PROFILE_INPUTS = "VALIDATE_PROFILE_INPUTS";
export const CLEAR_PROFILE_PRO_VALIDATE = "CLEAR_PROFILE_PRO_VALIDATE";
export const VALIDATE_PROFILE_PRO_INPUTS = "VALIDATE_PROFILE_PRO_INPUTS";
export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_PHOTO_URI = "SET_PHOTO_URI";
export const SET_PSEUDO = "SET_PSEUDO";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_SIRET_CODE = "SET_SIRET_CODE";
export const SET_BIRTHDATE = "SET_BIRTHDATE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ZIP_CODE = "SET_ZIP_CODE";
export const SET_CITY = "SET_CITY";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const SET_USER_PASSWORD_CONFIRM = "SET_USER_PASSWORD_CONFIRM";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_PHONE = "SET_PHONE";
export const SET_COMPANY_PHONE = "SET_COMPANY_PHONE";
export const SET_DESCRIPTION_FR = "SET_DESCRIPTION_FR";
export const SET_DESCRIPTION_EN = "SET_DESCRIPTION_EN";
export const SET_USER_HOBBIES = "SET_USER_HOBBIES";
export const FETCH_GET_USER_PROFILE = "FETCH_GET_USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_GENDER = "SET_GENDER";
export const FETCH_BLOCK_USER = "FETCH_BLOCK_USER";
export const SET_BLOCK_USER_RESULT = "SET_BLOCK_USER_RESULT";
export const FETCH_UNBLOCK_USER = "FETCH_UNBLOCK_USER";
export const SET_UNBLOCK_USER_RESULT = "SET_UNBLOCK_USER_RESULT";
export const FETCH_FLAG_INAPPROPRIATE_CONTENT =
	"FETCH_FLAG_INAPPROPRIATE_CONTENT";
export const SET_FLAG_INAPPROPRIATE_CONTENT_RESULT =
	"SET_FLAG_INAPPROPRIATE_CONTENT_RESULT";
export const SET_PROFILE_FILES_IMAGES = "SET_PROFILE_FILES_IMAGES";
export const REMOVE_PROFILE_IMAGE = "REMOVE_PROFILE_IMAGE";
export const SET_PROFILE_EDIT = "SET_PROFILE_EDIT";
export const SET_UPDATE_PROFILE_SUCCESS = "SET_UPDATE_PROFILE_SUCCESS";
export const SET_UPDATE_PROFILE_FAILURE = "SET_UPDATE_PROFILE_FAILURE";

export const FETCH_LOGOUT = "FETCH_LOGOUT";
export const LOGOUT_RESULT = "LOGOUT_RESULT";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FETCH_DELETE_USER = "FETCH_DELETE_USER";
export const DELETE_USER_RESULT = "DELETE_USER_RESULT";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const FETCH_CLEAR_PASSWORD = "FETCH_CLEAR_PASSWORD";
export const SET_CLEAR_PASSWORD_RESULT = "SET_CLEAR_PASSWORD_RESULT";
export const SET_CLEAR_PASSWORD_FAILURE = "SET_CLEAR_PASSWORD_FAILURE";

export const FETCH_CHANGE_PASSWORD = "FETCH_CHANGE_PASSWORD";
export const SET_CHANGE_PASSWORD_RESULT = "SET_CHANGE_PASSWORD_RESULT";
export const SET_CHANGE_PASSWORD_FAILURE = "SET_CHANGE_PASSWORD_FAILURE";
export const FETCH_ACTIVATE_ACCOUNT = "FETCH_ACTIVATE_ACCOUNT";
export const SET_ACTIVATE_ACCOUNT_RESULT = "SET_ACTIVATE_ACCOUNT_RESULT";
export const SET_ACTIVATE_ACCOUNT_FAILURE = "SET_ACTIVATE_ACCOUNT_FAILURE";
export const SET_USER_NEW_PASSWORD = "SET_USER_NEW_PASSWORD";
export const SET_USER_NEW_PASSWORD_CONFIRM = "SET_USER_NEW_PASSWORD_CONFIRM";
export const CLEAR_PASSWORD_VALIDATE = "CLEAR_PASSWORD_VALIDATE";
export const VALIDATE_PASSWORD_INPUTS = "VALIDATE_PASSWORD_INPUTS";
export const CLEAR_FORGET_PWD_VALIDATE = "CLEAR_FORGET_PWD_VALIDATE";
export const VALIDATE_FORGET_PWD_INPUT = "VALIDATE_FORGET_PWD_INPUT";

export const FETCH_GET_EMPLOYEES = "FETCH_GET_EMPLOYEES";
export const SET_EMPLOYEES_RESULT = "SET_EMPLOYEES_RESULT";
export const SET_EMPLOYEES_FAILURE = "SET_EMPLOYEES_FAILURE";
export const RESET_EMPLOYEE = "RESET_EMPLOYEE";
export const VALIDATE_EMPLOYEE_INPUTS = "VALIDATE_EMPLOYEE_INPUTS";
export const CLEAR_EMPLOYEE_VALIDATE = "CLEAR_EMPLOYEE_VALIDATE";
export const FETCH_REGISTER_EMPLOYEE = "FETCH_REGISTER_EMPLOYEE";
export const SET_REGISTER_EMPLOYEE_RESULT = "SET_REGISTER_EMPLOYEE_RESULT";
export const SET_REGISTER_EMPLOYEE_FAILURE = "SET_REGISTER_EMPLOYEE_FAILURE";
export const SET_EMPLOYEE_EMAIL = "SET_EMPLOYEE_EMAIL";
export const SET_EMPLOYEE_GENDER = "SET_EMPLOYEE_GENDER";
export const SET_EMPLOYEE_FIRST_NAME = "SET_EMPLOYEE_FIRST_NAME";
export const SET_EMPLOYEE_LAST_NAME = "SET_EMPLOYEE_LAST_NAME";
export const CLEAR_EMPLOYEE_EMAIL = "CLEAR_EMPLOYEE_EMAIL";
export const FETCH_DELETE_EMPLOYEE = "FETCH_DELETE_EMPLOYEE";
export const SET_DELETE_EMPLOYEE_RESULT = "SET_DELETE_EMPLOYEE_RESULT";
export const SET_DELETE_EMPLOYEE_FAILURE = "SET_DELETE_EMPLOYEE_FAILURE";
export const SET_ADD_EMPLOYEE_ROLE = "SET_ADD_EMPLOYEE_ROLE";

export const FETCH_REGISTER_PROFESSIONAL = "FETCH_REGISTER_PROFESSIONAL";
export const SET_REGISTER_PROFESSIONAL_RESULT =
	"SET_REGISTER_PROFESSIONAL_RESULT";
export const SET_REGISTER_PROFESSIONAL_FAILURE =
	"SET_REGISTER_PROFESSIONAL_FAILURE";

export const FETCH_PUSH_TOKEN = "FETCH_PUSH_TOKEN";
export const SET_TOKEN_RESULT = "SET_TOKEN_RESULT";

// LIVE
export const FETCH_GET_LIVE_EVENTS = "FETCH_GET_LIVE_EVENTS";
export const SET_LIVE_EVENTS = "SET_LIVE_EVENTS";
export const FETCH_DELETE_LIVE_EVENT = "FETCH_DELETE_LIVE_EVENT";
export const SET_DELETE_LIVE_EVENT_RESULT = "SET_DELETE_LIVE_EVENT_RESULT";
export const FETCH_INTERESTED_LIVE_EVENT = "FETCH_INTERESTED_LIVE_EVENT";
export const SET_INTERESTED_LIVE_EVENT_RESULT =
	"SET_INTERESTED_LIVE_EVENT_RESULT";
export const FETCH_UNINTERESTED_LIVE_EVENT = "FETCH_UNINTERESTED_LIVE_EVENT";
export const SET_UNINTERESTED_LIVE_EVENT_RESULT =
	"SET_UNINTERESTED_LIVE_EVENT_RESULT";
export const FETCH_GET_EVENT_INTERESTED_USERS =
	"FETCH_GET_EVENT_INTERESTED_USERS";
export const SET_EVENT_INTERESTED_USERS_RESULT =
	"SET_EVENT_INTERESTED_USERS_RESULT";

// METEO
export const FETCH_GET_FORECAST_METEO = "FETCH_GET_FORECAST_METEO";
export const SET_FORECAST_METEO = "SET_FORECAST_METEO";
export const FETCH_GET_CURRENT_METEO = "FETCH_GET_CURRENT_METEO";
export const SET_CURRENT_METEO = "SET_CURRENT_METEO";

// GOODDEALS
export const FETCH_GET_GOOD_DEALS = "FETCH_GET_GOOD_DEALS";
export const SET_GOOD_DEALS = "SET_GOOD_DEALS";
export const FETCH_GET_GOOD_DEAL_DETAILS = "FETCH_GET_GOOD_DEAL_DETAILS";
export const SET_GET_GOOD_DEAL_DETAILS_RESULT =
	"SET_GET_GOOD_DEAL_DETAILS_RESULT";
export const SET_GET_GOOD_DEAL_DETAILS_FAILURE =
	"SET_GET_GOOD_DEAL_DETAILS_FAILURE";
export const FETCH_GET_GOOD_DEALS_CATEGORIES =
	"FETCH_GET_GOOD_DEALS_CATEGORIES";
export const SET_GOOD_DEALS_CATEGORIES = "SET_GOOD_DEALS_CATEGORIES";
export const SET_GOOD_DEALS_CATEGORIES_FAILURE =
	"SET_GOOD_DEALS_CATEGORIES_FAILURE";
export const SET_LOCALIZATION = "SET_LOCALIZATION";
export const SET_GOODDEALS_CUSTOM_URI = "SET_GOODDEALS_CUSTOM_URI";
export const SET_CUSTOM_URI_ERROR = "SET_CUSTOM_URI_ERROR";
export const SET_GOODDEALS_CATEGORY = "SET_GOODDEALS_CATEGORY";
export const SET_GOODDEALS_TEMPLATE = "SET_GOODDEALS_TEMPLATE";
export const SET_GOODDEALS_FILES_IMAGES = "SET_GOODDEALS_FILES_IMAGES";
export const REMOVE_GOODDEALS_IMAGE = "REMOVE_GOODDEALS_IMAGE";
export const SET_GOODDEALS_PHONE = "SET_GOODDEALS_PHONE";
export const SET_GOODDEALS_PHONE_ERROR = "SET_GOODDEALS_PHONE_ERROR";
export const SET_WEB_SITE_URL = "SET_WEB_SITE_URL";
export const SET_WEB_SITE_URL_ERROR = "SET_WEB_SITE_URL_ERROR";
export const CLEAR_WEB_SITE_URL = "CLEAR_WEB_SITE_URL";
export const CLEAR_PHONE = "CLEAR_PHONE";
export const SET_IMAGE_ERROR = "SET_IMAGE_ERROR";
export const SET_GOODDEALS_TITLE_FR = "SET_GOODDEALS_TITLE_FR";
export const SET_GOODDEALS_PROMOTION_FR = "SET_GOODDEALS_PROMOTION_FR";
export const SET_GOODDEALS_DESCRIPTION_FR = "SET_GOODDEALS_DESCRIPTION_FR";
export const SET_GOODDEALS_TITLE_EN = "SET_GOODDEALS_TITLE_EN";
export const SET_GOODDEALS_PROMOTION_EN = "SET_GOODDEALS_PROMOTION_EN";
export const SET_GOODDEALS_DESCRIPTION_EN = "SET_GOODDEALS_DESCRIPTION_EN";
export const SET_GOODDEALS_OPENING_TIME_FR = "SET_GOODDEALS_OPENING_TIME_FR";
export const SET_GOODDEALS_OPENING_TIME_EN = "SET_GOODDEALS_OPENING_TIME_EN";
export const SET_GOODDEALS_PRICE_FR = "SET_GOODDEALS_PRICE_FR";
export const SET_GOODDEALS_PRICE_EN = "SET_GOODDEALS_PRICE_EN";
export const SET_GOODDEALS_EMAIL_ERROR = "SET_GOODDEALS_EMAIL_ERROR";
export const SET_GOODDEALS_CHOICES_TAGS = "SET_GOODDEALS_CHOICES_TAGS";
export const SELECT_GOODDEALS_ACTIVITY_CHOICE_FR =
	"SELECT_GOODDEALS_ACTIVITY_CHOICE_FR";
export const SELECT_GOODDEALS_ACTIVITY_CHOICE_EN =
	"SELECT_GOODDEALS_ACTIVITY_CHOICE_EN";
export const SET_GOODDEALS_ACTIVITY_NAME_EN = "SET_GOODDEALS_ACTIVITY_NAME_EN";
export const SET_GOODDEALS_ACTIVITY_DESCRIPTION_EN =
	"SET_GOODDEALS_ACTIVITY_DESCRIPTION_EN";
export const SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_EN =
	"SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_EN";
export const SET_GOODDEALS_ACTIVITY_DESCRIPTION_FR =
	"SET_GOODDEALS_ACTIVITY_DESCRIPTION_FR";
export const SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_FR =
	"SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_FR";
export const SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR =
	"SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR";
export const GOODDEALS_STEP2_VALIDATED = "GOODDEALS_STEP2_VALIDATED";
export const CLEAR_EMAIL = "CLEAR_EMAIL";
export const SET_TITLE_ERROR = "SET_TITLE_ERROR";
export const SET_DESCRIPTION_ERROR = "SET_DESCRIPTION_ERROR";
export const FETCH_ADD_GOOD_DEAL = "FETCH_ADD_GOOD_DEAL";
export const SET_ADD_GOOD_DEAL_RESULT = "SET_ADD_GOOD_DEAL_RESULT";
export const SET_ADD_GOOD_DEAL_ERROR = "SET_ADD_GOOD_DEAL_ERROR";
export const FETCH_UPDATE_GOOD_DEAL = "FETCH_UPDATE_GOOD_DEAL";
export const SET_UPDATE_GOOD_DEAL_RESULT = "SET_UPDATE_GOOD_DEAL_RESULT";
export const SET_UPDATE_GOOD_DEAL_ERROR = "SET_UPDATE_GOOD_DEAL_ERROR";
export const FETCH_GET_OWN_GOOD_DEALS = "FETCH_GET_OWN_GOOD_DEALS";
export const SET_OWN_GOOD_DEAL_RESULT = "SET_OWN_GOOD_DEAL_RESULT";
export const SET_OWN_GOOD_DEAL_FAILURE = "SET_OWN_GOOD_DEAL_FAILURE";
export const FETCH_DELETE_GOOD_DEAL = "FETCH_DELETE_GOOD_DEAL";
export const SET_DELETE_GOOD_DEAL_RESULT = "SET_DELETE_GOOD_DEAL_RESULT";
export const SET_DELETE_GOOD_DEAL_FAILURE = "SET_DELETE_GOOD_DEAL_FAILURE";
export const RESET_GOODDEAL = "RESET_GOODDEAL";
export const SET_GOODDEAL_SELECTED_CITY = "SET_GOODDEAL_SELECTED_CITY";
export const SET_GOODDEAL_SELECTED_ADDRESS = "SET_GOODDEAL_SELECTED_ADDRESS";
export const SET_GOODDEAL_SELECTED_ZIPCODE = "SET_GOODDEAL_SELECTED_ZIPCODE";
export const SET_GOODDEAL_ZIP_CODE = "SET_GOODDEAL_ZIP_CODE";
export const SET_GOODDEAL_CITY = "SET_GOODDEAL_CITY";
export const SET_GOODDEAL_EMAIL = "SET_GOODDEAL_EMAIL";
export const SET_GOODDEAL_ADDRESS = "SET_GOODDEAL_ADDRESS";
export const SET_GOODDEAL_SORTED = "SET_GOODDEAL_SORTED";
export const FETCH_GET_OWN_GOOD_DEALS_TITLES =
	"FETCH_GET_OWN_GOOD_DEALS_TITLES";
export const SET_OWN_GOOD_DEAL_TITLES_RESULT =
	"SET_OWN_GOOD_DEAL_TITLES_RESULT";
export const FETCH_PUBLISH_GOOD_DEAL = "FETCH_PUBLISH_GOOD_DEAL";
export const SET_PUBLISH_GOOD_DEAL_RESULT = "SET_PUBLISH_GOOD_DEAL_RESULT";
export const SET_PUBLISH_GOOD_DEAL_FAILURE = "SET_PUBLISH_GOOD_DEAL_FAILURE";
export const FETCH_MODERATING_GOOD_DEAL_ACCEPT =
	"FETCH_MODERATING_GOOD_DEAL_ACCEPT";
export const SET_MODERATING_GOOD_DEAL_ACCEPT_RESULT =
	"SET_MODERATING_GOOD_DEAL_ACCEPT_RESULT";
export const SET_MODERATING_GOOD_DEAL_ACCEPT_FAILURE =
	"SET_MODERATING_GOOD_DEAL_ACCEPT_FAILURE";
export const FETCH_MODERATING_GOOD_DEAL_REFUSE =
	"FETCH_MODERATING_GOOD_DEAL_REFUSE";
export const SET_MODERATING_GOOD_DEAL_REFUSE_RESULT =
	"SET_MODERATING_GOOD_DEAL_REFUSE_RESULT";
export const SET_MODERATING_GOOD_DEAL_REFUSE_FAILURE =
	"SET_MODERATING_GOOD_DEAL_REFUSE_FAILURE";
export const FETCH_GET_HOME_GOOD_DEALS = "FETCH_GET_HOME_GOOD_DEALS";
export const SET_HOME_GOODDEALS_RESULT = "SET_HOME_GOODDEALS_RESULT";
export const SET_HOME_GOOD_DEALS_FAILURE = "SET_HOME_GOOD_DEALS_FAILURE";
export const FETCH_GET_GOOD_DEAL_CHANNELS = "FETCH_GET_GOOD_DEAL_CHANNELS";
export const SET_GOOD_DEAL_CHANNELS_RESULT = "SET_GOOD_DEAL_CHANNELS_RESULT";
export const SET_GET_GOOD_DEAL_CHANNELS_FAILURE =
	"SET_GET_GOOD_DEAL_CHANNELS_FAILURE";
export const SET_FB_URL = "SET_FB_URL";
export const SET_FB_URL_ERROR = "SET_FB_URL_ERROR";
export const CLEAR_FB_URL = "CLEAR_FB_URL";
export const SET_ZIPCODE_ERROR = "SET_ZIPCODE_ERROR";
export const SET_CITY_ERROR = "SET_CITY_ERROR";

export const FETCH_PUBLISH_ACTIVITY_REQUEST = "FETCH_PUBLISH_ACTIVITY_REQUEST";
export const SET_PUBLISH_RESULT = "SET_PUBLISH_RESULT";

// CHAT
export const FETCH_GET_CHAT_MESSAGES = "FETCH_GET_CHAT_MESSAGES";
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const FETCH_GET_MY_CHAT_USERS = "FETCH_GET_MY_CHAT_USERS";
export const SET_MY_CHAT_USERS = "SET_MY_CHAT_USERS";
export const SET_MY_CHAT_USERS_FAILURE = "SET_MY_CHAT_USERS_FAILURE";
export const FETCH_GET_CHAT_CAMPERS = "FETCH_GET_CHAT_CAMPERS";
export const SET_CHAT_CAMPERS = "SET_CHAT_CAMPERS";
export const SET_CHAT_CAMPERS_FAILURE = "SET_CHAT_CAMPERS_FAILURE";
export const FETCH_GET_CHAT_EMPLOYEES = "FETCH_GET_CHAT_EMPLOYEES";
export const SET_CHAT_EMPLOYEES = "SET_CHAT_EMPLOYEES";
export const SET_CHAT_EMPLOYEES_FAILURE = "SET_CHAT_EMPLOYEES_FAILURE";
export const FETCH_EXIT_CHAT = "FETCH_EXIT_CHAT";
export const SET_CHAT_EXIT_RESULT = "SET_CHAT_EXIT_RESULT";

// TEMPLATES
// export const FETCH_GET_TEMPLATES_CATEGORIES = "FETCH_GET_TEMPLATES_CATEGORIES";
export const FETCH_GET_OTHER_TEMPLATES_CATEGORIES =
	"FETCH_GET_OTHER_TEMPLATES_CATEGORIES";
export const FETCH_GET_TEMPLATES_BY_CATEGORY =
	"FETCH_GET_TEMPLATES_BY_CATEGORY";
export const SET_TEMPLATE_BY_CATEGORY = "SET_TEMPLATE_BY_CATEGORY";
export const SET_TEMPLATE_CATEGORIES = "SET_TEMPLATE_CATEGORIES";
export const FETCH_GET_TEMPLATES_CATEGORIES = "FETCH_GET_TEMPLATES_CATEGORIES";
export const SET_TEMPLATES_CATEGORIES = "SET_TEMPLATES_CATEGORIES";
export const SET_TEMPLATES_CATEGORIES_FAILURE =
	"SET_TEMPLATES_CATEGORIES_FAILURE";
export const FETCH_GET_TEMPLATE_DATES = "FETCH_GET_TEMPLATE_DATES";
export const SET_TEMPLATE_DATES = "SET_TEMPLATE_DATES";
export const SET_TEMPLATE_DATES_FAILURE = "SET_TEMPLATE_DATES_FAILURE";
export const FETCH_GET_TEMPLATE_TIMES = "FETCH_GET_TEMPLATE_TIMES";
export const SET_TEMPLATE_TIMES = "SET_TEMPLATE_TIMES";
export const SET_TEMPLATE_TIMES_FAILURE = "SET_TEMPLATE_TIMES_FAILURE";
export const RESET_TEMPLATE = "RESET_TEMPLATE";
export const SET_TEMPLATE_IMAGE_ERROR = "SET_TEMPLATE_IMAGE_ERROR";
export const SET_TEMPLATE_NAME_ERROR = "SET_TEMPLATE_NAME_ERROR";
export const SET_TEMPLATE_DESCRIPTION_ERROR = "SET_TEMPLATE_DESCRIPTION_ERROR";
export const SET_TEMPLATE_CATEGORY_ERROR = "SET_TEMPLATE_CATEGORY_ERROR";
export const SET_TEMPLATE_CATEGORY_NAME_ERROR =
	"SET_TEMPLATE_CATEGORY_NAME_ERROR";
export const TEMPLATE_ON_DRAG_END = "TEMPLATE_ON_DRAG_END";
export const FETCH_REORDER_TEMPLATES_CATEGORIES =
	"FETCH_REORDER_TEMPLATES_CATEGORIES";
export const SET_REORDER_TEMPLATES_CATEGORIES_RESULT =
	"SET_REORDER_TEMPLATES_CATEGORIES_RESULT";
export const SET_REORDER_TEMPLATES_CATEGORIES_FAILURE =
	"SET_REORDER_TEMPLATES_CATEGORIES_FAILURE";
export const SET_TEMPLATE_PICTO = "SET_TEMPLATE_PICTO";

// LOCATIONS
export const FETCH_GET_LOCATIONS = "FETCH_GET_LOCATIONS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_LOCATIONS_RESULT = "SET_LOCATIONS_RESULT";
export const SET_LOCATIONS_FAILURE = "SET_LOCATIONS_FAILURE";
export const RESET_LOCATION = "RESET_LOCATION";
export const SET_LOCATION = "SET_LOCATION";
export const SET_LOCATION_NAME_FR = "SET_LOCATION_NAME_FR";
export const SET_LOCATION_NAME_EN = "SET_LOCATION_NAME_EN";
export const SET_LOCATION_NAME_ERROR = "SET_LOCATION_NAME_ERROR";
export const FETCH_ADD_LOCATION = "FETCH_ADD_LOCATION";
export const SET_ADD_LOCATION_RESULT = "SET_ADD_LOCATION_RESULT";
export const SET_ADD_LOCATION_FAILURE = "SET_ADD_LOCATION_FAILURE";
export const FETCH_UPDATE_LOCATION = "FETCH_UPDATE_LOCATION";
export const SET_UPDATE_LOCATION_RESULT = "SET_UPDATE_LOCATION_RESULT";
export const SET_UPDATE_LOCATION_FAILURE = "SET_UPDATE_LOCATION_FAILURE";
export const FETCH_DELETE_LOCATION = "FETCH_DELETE_LOCATION";
export const SET_DELETE_LOCATION_RESULT = "SET_DELETE_LOCATION_RESULT";
export const SET_DELETE_LOCATION_FAILURE = "SET_DELETE_LOCATION_FAILURE";
export const SET_LOCATION_ORDER = "SET_LOCATION_ORDER";
export const FETCH_REORDER_LOCATIONS = "FETCH_REORDER_LOCATIONS";
export const SET_REORDER_LOCATIONS_RESULT = "SET_REORDER_LOCATIONS_RESULT";
export const SET_REORDER_LOCATIONS_FAILURE = "SET_REORDER_LOCATIONS_FAILURE";
export const LOCATIONS_ON_DRAG_END = "LOCATIONS_ON_DRAG_END";
export const SET_LOCATION_WIZARD_STEP = "SET_LOCATION_WIZARD_STEP";
export const ADD_LOCATION = "ADD_LOCATION";

// EQUIPMENTS
export const FETCH_GET_EQUIPMENTS = "FETCH_GET_EQUIPMENTS";
export const SET_EQUIPMENTS = "SET_EQUIPMENTS";
export const SET_EQUIPMENTS_RESULT = "SET_EQUIPMENTS_RESULT";
export const SET_EQUIPMENTS_FAILURE = "SET_EQUIPMENTS_FAILURE";
export const RESET_EQUIPMENT = "RESET_EQUIPMENT";
export const SET_EQUIPMENT = "SET_EQUIPMENT";
export const SET_EQUIPMENT_NAME_FR = "SET_EQUIPMENT_NAME_FR";
export const SET_EQUIPMENT_NAME_EN = "SET_EQUIPMENT_NAME_EN";
export const SET_EQUIPMENT_NAME_ERROR = "SET_EQUIPMENT_NAME_ERROR";
export const FETCH_ADD_EQUIPMENT = "FETCH_ADD_EQUIPMENT";
export const SET_ADD_EQUIPMENT_RESULT = "SET_ADD_EQUIPMENT_RESULT";
export const SET_ADD_EQUIPMENT_FAILURE = "SET_ADD_EQUIPMENT_FAILURE";
export const FETCH_UPDATE_EQUIPMENT = "FETCH_UPDATE_EQUIPMENT";
export const SET_UPDATE_EQUIPMENT_RESULT = "SET_UPDATE_EQUIPMENT_RESULT";
export const SET_UPDATE_EQUIPMENT_FAILURE = "SET_UPDATE_EQUIPMENT_FAILURE";
export const FETCH_DELETE_EQUIPMENT = "FETCH_DELETE_EQUIPMENT";
export const SET_DELETE_EQUIPMENT_RESULT = "SET_DELETE_EQUIPMENT_RESULT";
export const SET_DELETE_EQUIPMENT_FAILURE = "SET_DELETE_EQUIPMENT_FAILURE";
export const SET_EQUIPMENT_ORDER = "SET_EQUIPMENT_ORDER";
export const SET_EQUIPMENT_SYSTEM = "SET_EQUIPMENT_SYSTEM";
export const EQUIPMENTS_ON_DRAG_END = "EQUIPMENTS_ON_DRAG_END";
export const FETCH_REORDER_EQUIPMENTS = "FETCH_REORDER_EQUIPMENTS";
export const SET_REORDER_EQUIPMENTS_RESULT = "SET_REORDER_EQUIPMENTS_RESULT";
export const SET_REORDER_EQUIPMENTS_FAILURE = "SET_REORDER_EQUIPMENTS_FAILURE";
export const SET_EQUIPMENT_WIZARD_STEP = "SET_EQUIPMENT_WIZARD_STEP";

// SETTINGS
export const FETCH_GET_NOTIF_USER_TEMPLATES = "FETCH_GET_NOTIF_USER_TEMPLATES";
export const FETCH_SET_NOTIF_USER_TEMPLATES = "FETCH_SET_NOTIF_USER_TEMPLATES";
export const FETCH_DELETE_NOTIF_USER_TEMPLATE =
	"FETCH_DELETE_NOTIF_USER_TEMPLATE";
export const SET_NOTIF_USER_TEMPLATES = "SET_NOTIF_USER_TEMPLATES";
export const SET_NOTIF_USER_TEMPLATES_FAILURE =
	"SET_NOTIF_USER_TEMPLATES_FAILURE";

export const SET_LANG = "SET_LANG";

export const FETCH_GET_COUNTERS = "FETCH_GET_COUNTERS";
export const SET_COUNTERS = "SET_COUNTERS";
export const SET_COUNTERS_FAILURE = "SET_COUNTERS_FAILURE";
export const FETCH_GET_GLOBAL_INFORMATIONS = "FETCH_GET_GLOBAL_INFORMATIONS";
export const FETCH_GET_GLOBAL_INFORMATIONS_VERSION =
	"FETCH_GET_GLOBAL_INFORMATIONS_VERSION";
export const SET_GLOBAL_INFORMATIONS = "SET_GLOBAL_INFORMATIONS";
export const SET_GLOBAL_INFORMATIONS_VERSIONS =
	"SET_GLOBAL_INFORMATIONS_VERSIONS";
export const SET_GLOBAL_INFORMATIONS_FAILURE =
	"SET_GLOBAL_INFORMATIONS_FAILURE";
export const SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE =
	"SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE";
export const SET_CONTACT_MESSAGE = "SET_CONTACT_MESSAGE";
export const SET_CONTACT_SUBJECT = "SET_CONTACT_SUBJECT";
export const SET_CONTACT_PHONE = "SET_CONTACT_PHONE";
export const CLEAR_CONTACT_PHONE = "CLEAR_CONTACT_PHONE";
export const FETCH_SEND_EMAIL = "FETCH_SEND_EMAIL";
export const SET_SEND_EMAIL_RESULT = "SET_SEND_EMAIL_RESULT";
export const SET_SEND_EMAIL_FAILURE = "SET_SEND_EMAIL_FAILURE";
export const SET_CONTACT_EMAIL = "SET_CONTACT_EMAIL";
export const CLEAR_CONTACT_EMAIL = "CLEAR_CONTACT_EMAIL";
export const SET_CONTACT_COMPANY = "SET_CONTACT_COMPANY";
export const SET_CONTACT_NAME = "SET_CONTACT_NAME";
export const CLEAR_CONTACT_VALIDATE = "CLEAR_CONTACT_VALIDATE";
export const VALIDATE_CONTACT = "VALIDATE_CONTACT";

export const FETCH_UPLOAD_IMAGES = "FETCH_UPLOAD_IMAGES";
export const SET_UPLOAD_IMAGES_RESULT = "SET_UPLOAD_IMAGES_RESULT";
export const SET_UPLOAD_IMAGES_FAILURE = "SET_UPLOAD_IMAGES_FAILURE";
export const SET_SUBFOLDER_IMAGES = "SET_SUBFOLDER_IMAGES";
export const SET_FILES_IMAGES = "SET_FILES_IMAGES";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const FETCH_GET_CACHED_IMAGES = "FETCH_GET_CACHED_IMAGES";
export const SET_CACHED_IMAGES = "SET_CACHED_IMAGES";
export const SET_CACHED_IMAGES_FAILURE = "SET_CACHED_IMAGES_FAILURE";
export const FETCH_DELETE_IMAGE = "FETCH_DELETE_IMAGE";
export const SET_DELETE_IMAGE_RESULT = "SET_DELETE_IMAGE_RESULT";
export const SET_DELETE_IMAGE_FAILURE = "SET_DELETE_IMAGE_FAILURE";
export const SET_IMAGE_NAME = "SET_IMAGE_NAME";
export const SET_IMAGE_TYPE = "SET_IMAGE_TYPE";
export const CLEAR_UPLOAD_FILE_VALIDATE = "CLEAR_UPLOAD_FILE_VALIDATE";
export const VALIDATE_UPLOAD_FILE = "VALIDATE_UPLOAD_FILE";
export const FETCH_GET_PICTOS_URI = "FETCH_GET_PICTOS_URI";
export const SET_PICTOS_URI = "SET_PICTOS_URI";
export const SET_PICTOS_URI_FAILURE = "SET_PICTOS_URI_FAILURE";

export const ADD_GOODDEAL_CAMPSITE = "ADD_GOODDEAL_CAMPSITE";
export const DELETE_GOODDEAL_CAMPSITE = "DELETE_GOODDEAL_CAMPSITE";
export const MISSING_CAMPSITE = "MISSING_CAMPSITE";
export const ADD_GOODDEAL_CITY = "ADD_GOODDEAL_CITY";
export const DELETE_GOODDEAL_CITY = "DELETE_GOODDEAL_CITY";
export const MISSING_CITY = "MISSING_CITY";
export const FETCH_GET_GOODDEAL_CHANNELS = "FETCH_GET_GOODDEAL_CHANNELS";
export const SET_GOODDEAL_CHANNELS = "SET_GOODDEAL_CHANNELS";
export const SET_GOODDEAL_CHANNELS_FAILURE = "SET_GOODDEAL_CHANNELS_FAILURE";
export const ADD_GOODDEAL_CHANNEL = "ADD_GOODDEAL_CHANNEL";
export const DELETE_GOODDEAL_CHANNEL = "DELETE_GOODDEAL_CHANNEL";
export const MISSING_CHANNEL = "MISSING_CHANNEL";
export const SET_EVENT_CHANNELS = "SET_EVENT_CHANNELS";
export const SET_EVENT_CHANNELS_FAILURE = "SET_EVENT_CHANNELS_FAILURE";
export const ADD_GOODDEAL_CITY_CHANNEL = "ADD_GOODDEAL_CITY_CHANNEL";
export const DELETE_GOODDEAL_CITY_CHANNEL = "DELETE_GOODDEAL_CITY_CHANNEL";
export const ADD_GOODDEAL_CAMPSITE_CITY_CHANNEL =
	"ADD_GOODDEAL_CAMPSITE_CITY_CHANNEL";
export const DELETE_GOODDEAL_CAMPSITE_CITY_CHANNEL =
	"DELETE_GOODDEAL_CAMPSITE_CITY_CHANNEL";
export const ADD_GOODDEAL_CAMPSITE_CHANNEL = "ADD_GOODDEAL_CAMPSITE_CHANNEL";
export const DELETE_GOODDEAL_CAMPSITE_CHANNEL =
	"DELETE_GOODDEAL_CAMPSITE_CHANNEL";
export const ADD_GOODDEAL_NEIGHBORHOOD_CITY_CHANNEL =
	"ADD_GOODDEAL_NEIGHBORHOOD_CITY_CHANNEL";
export const DELETE_GOODDEAL_NEIGHBORHOOD_CITY_CHANNEL =
	"DELETE_GOODDEAL_NEIGHBORHOOD_CITY_CHANNEL";
export const ADD_GOODDEAL_NEIGHBORHOOD_CHANNEL =
	"ADD_GOODDEAL_NEIGHBORHOOD_CHANNEL";
export const DELETE_GOODDEAL_NEIGHBORHOOD_CHANNEL =
	"DELETE_GOODDEAL_NEIGHBORHOOD_CHANNEL";

export const FETCH_ADD_TEMPLATE = "FETCH_ADD_TEMPLATE";
export const SET_ADD_TEMPLATE_RESULT = "SET_ADD_TEMPLATE_RESULT";
export const SET_ADD_TEMPLATE_FAILURE = "SET_ADD_TEMPLATE_FAILURE";
export const SET_TEMPLATE_FILES_IMAGES = "SET_TEMPLATE_FILES_IMAGES";
export const REMOVE_TEMPLATE_IMAGE = "REMOVE_TEMPLATE_IMAGE";
export const SET_TEMPLATE_CATEGORY = "SET_TEMPLATE_CATEGORY";
export const SET_TEMPLATE_NEW_CATEGORY = "SET_TEMPLATE_NEW_CATEGORY";
export const SET_TEMPLATE_NAME_FR = "SET_TEMPLATE_NAME_FR";
export const SET_TEMPLATE_NAME_EN = "SET_TEMPLATE_NAME_EN";
export const SET_TEMPLATE_DESCRIPTION_FR = "SET_TEMPLATE_DESCRIPTION_FR";
export const SET_TEMPLATE_DESCRIPTION_EN = "SET_TEMPLATE_DESCRIPTION_EN";
export const SET_ACTIVITY_GOODDEAL_DESCRIPTION_FR =
	"SET_ACTIVITY_GOODDEAL_DESCRIPTION_FR";
export const SET_ACTIVITY_GOODDEAL_DESCRIPTION_EN =
	"SET_ACTIVITY_GOODDEAL_DESCRIPTION_EN";
export const SET_TEMPLATE_POST_DESCRIPTION_FR =
	"SET_TEMPLATE_POST_DESCRIPTION_FR";
export const SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_FR =
	"SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_FR";
export const SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_EN =
	"SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_EN";
export const SET_TEMPLATE_POST_DESCRIPTION_EN =
	"SET_TEMPLATE_POST_DESCRIPTION_EN";
export const FETCH_GET_TEMPLATES_CATEGORIES_NAMES =
	"FETCH_GET_TEMPLATES_CATEGORIES_NAMES";
export const FETCH_GET_TEMPLATES_CATEGORIES_IDS =
	"FETCH_GET_TEMPLATES_CATEGORIES_IDS";
export const SET_TEMPLATES_CATEGORIES_NAMES_RESULT =
	"SET_TEMPLATES_CATEGORIES_NAMES_RESULT";
export const SET_TEMPLATES_CATEGORIES_IDS_RESULT =
	"SET_TEMPLATES_CATEGORIES_IDS_RESULT";
export const SET_TEMPLATES_DATA_FAILURE = "SET_TEMPLATES_DATA_FAILURE";
export const FETCH_GET_TEMPLATES_DATES = "FETCH_GET_TEMPLATES_DATES";
export const SET_TEMPLATES_DATES_RESULT = "SET_TEMPLATES_DATES_RESULT";
export const FETCH_GET_TEMPLATES_TIMES = "FETCH_GET_TEMPLATES_TIMES";
export const SET_TEMPLATES_TIMES_RESULT = "SET_TEMPLATES_TIMES_RESULT";
export const SET_TEMPLATE_DATE = "SET_TEMPLATE_DATE";
export const SET_TEMPLATE_TIME = "SET_TEMPLATE_TIME";
export const SET_TEMPLATE_EQUIPMENT = "SET_TEMPLATE_EQUIPMENT";
export const SET_CAMPSITE_DETAIL_RESULT = "SET_CAMPSITE_DETAIL_RESULT";
export const TOGGLE_ACCEPT_NOTIF = "TOGGLE_ACCEPT_NOTIF";
export const FETCH_UPDATE_TEMPLATE = "FETCH_UPDATE_TEMPLATE";
export const SET_UPDATE_TEMPLATE_RESULT = "SET_UPDATE_TEMPLATE_RESULT";
export const SET_UPDATE_TEMPLATE_FAILURE = "SET_UPDATE_TEMPLATE_FAILURE";
export const FETCH_DELETE_TEMPLATE = "FETCH_DELETE_TEMPLATE";
export const SET_DELETE_TEMPLATE_RESULT = "SET_DELETE_TEMPLATE_RESULT";
export const SET_DELETE_TEMPLATE_FAILURE = "SET_DELETE_TEMPLATE_FAILURE";
export const RESET_TEMPLATE_CATEGORY = "RESET_TEMPLATE_CATEGORY";
export const FETCH_DELETE_TEMPLATE_CATEGORY = "FETCH_DELETE_TEMPLATE_CATEGORY";
export const SET_DELETE_TEMPLATE_CATEGORY_RESULT =
	"SET_DELETE_TEMPLATE_CATEGORY_RESULT";
export const SET_DELETE_TEMPLATE_CATEGORY_FAILURE =
	"SET_DELETE_TEMPLATE_CATEGORY_FAILURE";
export const FETCH_UPDATE_TEMPLATE_CATEGORY = "FETCH_UPDATE_TEMPLATE_CATEGORY";
export const SET_UPDATE_TEMPLATE_CATEGORY_RESULT =
	"SET_UPDATE_TEMPLATE_CATEGORY_RESULT";
export const SET_UPDATE_TEMPLATE_CATEGORY_FAILURE =
	"SET_UPDATE_TEMPLATE_CATEGORY_FAILURE";
export const SET_TEMPLATE_TYPE = "SET_TEMPLATE_TYPE";
export const SET_TEMPLATE_SYSTEM = "SET_TEMPLATE_SYSTEM";
export const SET_TEMPLATE_CATEGORY_SYSTEM = "SET_TEMPLATE_CATEGORY_SYSTEM";
export const SET_ACTIVITY_DESCRIPTION_FR = "SET_ACTIVITY_DESCRIPTION_FR";
export const SET_ACTIVITY_DESCRIPTION_EN = "SET_ACTIVITY_DESCRIPTION_EN";
export const SET_ACTIVITY_POST_DESCRIPTION_EN =
	"SET_ACTIVITY_POST_DESCRIPTION_EN";
export const SET_ACTIVITY_POST_DESCRIPTION_FR =
	"SET_ACTIVITY_POST_DESCRIPTION_FR";
export const SET_CHOICES_TAGS = "SET_CHOICES_TAGS";
export const SELECT_ACTIVITY_CHOICE_FR = "SELECT_ACTIVITY_CHOICE_FR";
export const SELECT_ACTIVITY_CHOICE_EN = "SELECT_ACTIVITY_CHOICE_EN";
export const SET_ACTIVITY_NAME_EN = "SET_ACTIVITY_NAME_EN";
export const STEP2_VALIDATED = "STEP2_VALIDATED";
export const SET_ACTIVITY_NAME_FR1 = "SET_ACTIVITY_NAME_FR1";

export const FETCH_ADD_TEMPLATE_CATEGORY = "FETCH_ADD_TEMPLATE_CATEGORY";
export const SET_ADD_TEMPLATE_CATEGORY_RESULT =
	"SET_ADD_TEMPLATE_CATEGORY_RESULT";
export const SET_ADD_TEMPLATE_CATEGORY_FAILURE =
	"SET_ADD_TEMPLATE_CATEGORY_FAILURE";
export const SET_TEMPLATE_CATEGORY_NAME_FR = "SET_TEMPLATE_CATEGORY_NAME_FR";
export const SET_TEMPLATE_CATEGORY_NAME_EN = "SET_TEMPLATE_CATEGORY_NAME_EN";
export const SET_TEMPLATE_CATEGORY_ORDER = "SET_TEMPLATE_CATEGORY_ORDER";
export const FETCH_GET_TEMPLATES = "FETCH_GET_TEMPLATES";
export const SET_TEMPLATES_RESULT = "SET_TEMPLATES_RESULT";
export const SET_TEMPLATES_FAILURE = "SET_TEMPLATES_FAILURE";
export const FETCH_GET_OWN_TEMPLATE_DETAILS = "FETCH_GET_OWN_TEMPLATE_DETAILS";
export const SET_GET_TEMPLATE_DETAILS_RESULT =
	"SET_GET_TEMPLATE_DETAILS_RESULT";
export const SET_GET_TEMPLATE_DETAILS_FAILURE =
	"SET_GET_TEMPLATE_DETAILS_FAILURE";
export const FETCH_CLONE_TEMPLATE_CATEGORY = "FETCH_CLONE_TEMPLATE_CATEGORY";
export const SET_CLONE_TEMPLATE_CATEGORY_RESULT =
	"SET_CLONE_TEMPLATE_CATEGORY_RESULT";
export const SET_CLONE_TEMPLATE_CATEGORY_FAILURE =
	"SET_CLONE_TEMPLATE_CATEGORY_FAILURE";
export const FETCH_CLONE_TEMPLATE = "FETCH_CLONE_TEMPLATE";
export const SET_CLONE_TEMPLATE_RESULT = "SET_CLONE_TEMPLATE_RESULT";
export const SET_CLONE_TEMPLATE_FAILURE = "SET_CLONE_TEMPLATE_FAILURE";
export const FETCH_GET_GOOD_DEALS_TEMPLATES = "FETCH_GET_GOOD_DEALS_TEMPLATES";
export const SET_GOOD_DEALS_TEMPLATES = "SET_GOOD_DEALS_TEMPLATES";
export const SET_GOOD_DEALS_TEMPLATES_FAILURE =
	"SET_GOOD_DEALS_TEMPLATES_FAILURE";

export const FETCH_GET_ALL_DATA = "FETCH_GET_ALL_DATA";
export const RESET_DATA = "RESET_DATA";

export const LIVE_ON_TOP = "LIVE_ON_TOP";
export const SET_FILTER = "SET_FILTER";
export const DISPLAY_ALL = "all";
export const DISPLAY_ONLY_CAMPSITE_POSTS = "campsite";
export const DISPLAY_ONLY_MY_POSTS = "my";

export const GROUP_ID_WEATHER = "weather";
export const GROUP_ID_ACTIVITY = "activity";
export const GROUP_ID_TEMPLATE = "template";
export const GROUP_ID_PUBLISH = "publish";
export const GROUP_ID_ANSWERS = "answers";

export const FETCH_GET_CITY_USING_ZIPCODE = "FETCH_GET_CITY_USING_ZIPCODE";
export const SET_CITY_USING_ZIPCODE_RESULT = "SET_CITY_USING_ZIPCODE_RESULT";
export const SET_CITY_USING_ZIPCODE_FAILURE = "SET_CITY_USING_ZIPCODE_FAILURE";
export const FETCH_GET_ADDRESS_USING_ZIPCODE =
	"FETCH_GET_ADDRESS_USING_ZIPCODE";
export const SET_ADDRESS_USING_ZIPCODE_RESULT =
	"SET_ADDRESS_USING_ZIPCODE_RESULT";
export const SET_ADDRESS_USING_ZIPCODE_FAILURE =
	"SET_ADDRESS_USING_ZIPCODE_FAILURE";
export const FETCH_GET_ADDRESS_USING_COORDINATES =
	"FETCH_GET_ADDRESS_USING_COORDINATES";
export const SET_ADDRESS_USING_COORDINATES_RESULT =
	"SET_ADDRESS_USING_COORDINATES_RESULT";
export const SET_ADDRESS_USING_COORDINATES_FAILURE =
	"SET_ADDRESS_USING_COORDINATES_FAILURE";

export const SET_STRIPE_TOKEN = "SET_STRIPE_TOKEN";
export const FETCH_PAY_GOOD_DEAL = "FETCH_PAY_GOOD_DEAL";
export const SET_FETCH_PAY_GOOD_DEAL_RESULT = "SET_FETCH_PAY_GOOD_DEAL_RESULT";
export const SET_FETCH_PAY_GOOD_DEAL_FAILURE =
	"SET_FETCH_PAY_GOOD_DEAL_FAILURE";

export const FETCH_GET_AUDITS = "FETCH_GET_AUDITS";
export const SET_AUDITS = "SET_AUDITS";
export const SET_AUDITS_FAILURE = "SET_AUDITS_FAILURE";
