import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

// core components
import AdminLayout from "./layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import configureStore from "./store/configure-store";
import CookieConsent from "react-cookie-consent";

// import AOS from "aos";
// import "aos/dist/aos.css";
import "app.css";
import AdminLoginPage from "./views/Pages/AdminLoginPage.js";
import MaintenancePage from "views/Pages/MaintenancePage.js";

// AOS.init({
//   aos: "flip-left",
//   // disable: "mobile",
//   duration: 2000
// });

if (process.env.NODE_ENV === "development") {
	import("./ReactotronConfig").then(() => console.log("Reactotron Configured"));
}

const history = createBrowserHistory();

const store = configureStore(history);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route exact path="/" component={AdminLoginPage} />
				<Route exact path="/connexion" component={AdminLoginPage} />
				<Route exact path="/maintenance" component={MaintenancePage} />
				<Route path="/team" component={AdminLayout} />
				<Route path="/webmaster" component={AdminLayout} />
				<Route path="/admin" component={AdminLayout} />
				<Redirect to="/" />
			</Switch>
		</ConnectedRouter>
		<CookieConsent buttonText="C'est noté">
			Nous utilisons des cookies pour vous garantir la meilleure expérience sur
			notre site web. Si vous continuez à utiliser ce site, nous supposerons que
			vous êtes satisfait.
		</CookieConsent>
	</Provider>,
	document.getElementById("root")
);

// document.onreadystatechange = function() {
//   if (document.readyState === "complete") {
//     AOS.refresh();
//   }
// };
